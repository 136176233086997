.footer__wrapper {
  width: 100%;
  padding: 30px 125px;
  background: white;
  border-top: 1px solid #e4e4e4;

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }

  @media (max-width: 680px) {
    padding: 30px 60px;
  }

  @media (max-width: 520px) {
    padding: 30px 20px;
  }
}


.footer__align {
  text-align: right;
  width: 100%;
  @media (max-width: 575px) {
    text-align: center;

    .social-media {
      display: none;
    }
  }
}

.footer__show-575 {
  display: none;
  @media (max-width: 575px) {
    display: block;
  }
}
