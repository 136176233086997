@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');
$primary: #3ca898;
$secondary: #5099ff;
$danger: #ff5500;
$alerts: rgb(123, 104, 238);
$cian: #3ca898;
$success: #52c41a;
$text-secondary: rgba(41, 45, 52, .7);
$dark: #212129;
$dark-light: #F1F1F1;

$border-color: #a1a1a1;
$border-radius: 8px;
$shadow-color: rgba(0,0,0,0.12);
$shadow-small: 0 3px 9px 0 $shadow-color;

