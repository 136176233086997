.shared__wrapper {
  display: flex;
}

.shared__labels {
  position: relative;
  padding: 10px;
  width: 375px;
  max-width: 100%;
  height: calc(100vh - 70px);
  background: #FFFFFF;
  border-top: 1px solid $dark-light;
  border-right: 1px solid $dark-light;
}

.shared__content {
  width: 100%;
  padding: 50px 20px;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.shared_menu_icon {
  top: 10px;
  z-index: 2;
  right: -15px;
  cursor: pointer;
  color: $primary;
  font-size: 18px;
  position: absolute;
  height: fit-content;
  width: fit-content;
  padding: 3px 7px;

  &:hover {
    border: 3px solid transparent;
    transition: 250ms all;
    right: -18px;
  }
}

.shared_menu_hidden {
  display: none;
  top: 10px;
  z-index: 2;
  left: 20px;
  height: fit-content;
  width: fit-content;
  padding: 3px 7px;
  cursor: pointer;
  color: $primary;
  position: absolute;

  &:hover {
    border: 3px solid #FFFFFF;
    transition: 250ms all;
  }
}

.shared_menu_close {
  .shared_menu_hidden {
    display: block;
  }
}


.shared_menu_open {
  @media screen and (max-width: 768px) {
    position: absolute;
    z-index: 10;
    max-width: calc(100% - 20px);
  }
}
