.card__list-wrapper-dash {
  width: 100%;
  max-width: 100%;
  overflow-x: scroll;
  transition: all 300ms;
  cursor: pointer;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }

  &:hover {
    transform: scale(1.02);
    transition: all 300ms;
  }
}

.card__list_dash {
  display: flex;
  width: 100%;
  padding: 10px;
  min-width: 600px;
  max-width: 100%;
  align-items: center;
  background: #FFFFFF;
  flex-direction: row;
  margin-bottom: 3px;
  justify-content: space-between;
}

.card__content-flex {
  display: flex;
  align-items: center;
}

.card__title-head {
  padding-left: 10px;
  line-height: 14px;
  width: 350px;
  max-width: 100%;

  .name {
    display: block;
    color: #202124;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    margin-right: 12px;
    font: 400 13px/1 Gotham Pro, Proxima Nova, arial, serif;
    font-weight: 700;
  }

  .email {
    display: block;
    color: #202124;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    align-items: center;
    margin-right: 12px;
    font: 400 13px/2 Gotham Pro, Proxima Nova, arial, serif;
  }

  @media (max-width: 550px) {
    width: 220px;
  }
}

.card__details {
  padding: 0 5px;
  max-width: 100%;
  transition: all 250ms;
}

.card__custom-avatar {
  position: relative;
  border-radius: 50%;
  //height: 32px;
  overflow: hidden;
  //width: 32px;

  .avatar_state {
    position: absolute;
    background: #FFFFFF;
    border-radius: 50%;
    bottom: 1px;
    height: 12px;
    margin-left: 0;
    margin-right: 0;
    right: 4px;
    width: 12px;
    outline: 0;
    padding: 0;
    vertical-align: baseline;

    .wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      position: absolute;
      align-items: center;
      justify-content: center;

      svg {
        fill: #5f6368;
      }
    }
  }
}

.card__custom-avatar .avatar_state .wrapper svg {
  fill: #52C41A;
}

.enable {
  color: #7c828d;
  font: 500 10px/12px Gotham Pro, Proxima Nova, arial, serif;
  text-transform: uppercase;
  text-align: center;
  transition: all 250ms;

  .anticon {
    font-size: 12px;
  }
}
