.auth__form-zumpul {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 175px;
  height: 72px;
  background-size: contain;
  background-repeat: no-repeat no-repeat;
  margin-top: 20px;
  margin-left: 20px;
  margin-right: auto;
}

.auth__mobile-wrapper {
  position: relative;
  z-index: -1;
  justify-content: center;
  align-items: center;
  display: none;
  width: 100%;
  height: 75vh;
  background-size: cover;
  background-repeat: no-repeat no-repeat;
  background-position: bottom right;

  @media (max-width: 520px) {
      display: flex;
  }
}

.auth__content {
  position: relative;
  width: 100%;
  padding: 0;
}

.auth__wrapper_login {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  @media (max-width: 520px) {
    height: auto;
    min-height: 100vh;
  }
}

.auth__info {
  position: relative;
  width: 50%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: $text-secondary;
  }
}

.auth__login-content {
  position: relative;
  width: 65%;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-size: contain;
  background-position: right top;
  background-repeat: no-repeat no-repeat;

  &.inverted {
    height: 100vh;
    background-size: cover;
    background-position: right bottom;
  }

  @media (max-width: 768px) {
      display: none;

    &.inverted {
      position: absolute;
      display: flex;

      h1 {
        display: none;
      }
    }
  }
}

.media_iframe_video {
  position: relative;
  width: 450px;
  height: 277px;
  padding: 22px 5px;
  background: #FFFFFF;
  border-radius: 12px 12px 4px 4px;
  box-shadow: 0 2px 5px 0 #a193a7;
  z-index: 100;

  &.mobile {
    width: 350px;
    max-width: calc(100% - 20px);
  }

  .tag {
    position: absolute;
    top: 7px;
    right: 0;
    height: 8px
  }
}

@media screen and (max-width: 768px) {
  .auth__info {
    width: 100%;
    margin-top: 85px;
    margin-bottom: 52px;
    align-items: center;
  }
  .auth__login-content {
    display: none;
  }
}

@media screen and (max-width: 520px) {
  .auth__info {
    margin-top: 0;
    align-items: flex-start;
  }
  .auth__wrapper_login {
    min-height: auto;
  }
}

.auth__form {
  width: 350px;
  margin-top: 30px;
  margin-right: auto;
  margin-left: auto;
}

.social__google-btn {
  height: 50px;
  width: 240px;
  margin-left: auto;
  margin-right: auto;
  border: none;
  -webkit-border-radius: 1px;
  border-radius: 1px;
  -webkit-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, .25);
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -webkit-appearance: none;
  background-image: none;
  cursor: pointer;
  outline: none;
  overflow: hidden;
  position: relative;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  transition: 300ms all;
  font-weight: 600;
  color: #444444 !important;

  &:hover {
    transform: scale(1.03);
    transition: 300ms all;
  }

  .social__google-btn-wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;

    .social__google-btn-image {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px;
      background-color: #fff;
      -webkit-border-radius: 1px;
      border-radius: 1px;
      float: left;

      img {
        width: 18px;
        height: 18px;
      }
    }

    span {
      font-size: 16px;
      line-height: 48px;
      font-weight: 500;
      letter-spacing: .21px;
      margin-left: 6px;
      margin-right: 6px;
      vertical-align: top;
    }
  }
}

.social__google-btn:hover {
  cursor: pointer;
}
