.signatures__actionsWrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.signatures__actionsBtn {
  background: #e7e7e7;
  font-weight: 500;
  margin-left: 20px;
  margin-bottom: 10px;

  &.active {
    background: $primary;
    color: white;

    &:hover {
      opacity: .6;
    }
  }
}

.signatures__table-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .col-responsive {
    width: 50%;

    &.mr-responsive {
      padding-right: 10px;
    }
  }

  @media (max-width: 728px) {
    .col-responsive {
      width: 100%;

      &.mr-responsive {
        margin-right: 0;
      }
    }
  }

}

.signatures__table-check {
  color: $secondary
}

.signatures__table-close{
  color: $primary
}

.signatures__table-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 250px;
  background: white;
  border-radius: $border-radius;
  .icon {
    font-size: 60px;
    color: $text-secondary;
  }
}
