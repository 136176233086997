.settings__wrapper {
  width: 100%;
  margin-top: 30px;
}

.settings__head-user {
  width: 100%;
  text-align: center;
}

.settings__licenses {
  display: block;
}

.settings__licenses-item {
  display: flex;
  justify-content: space-around;
}
