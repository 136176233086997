.dashboard___wrapper-main-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
  min-height: 0;
  height: 100%;
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

.dashboard__main-content {
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  display: grid;
  grid: 1fr/auto 1fr 0;
  background-color: $dark-light;
}

.dashboard__aside-wrapper {
  position: relative;
}

.dashboard__aside {
  grid-area: menu;
  display: grid;
  grid: 1fr/auto auto;
  height: 100vh;
  position: relative;
  background-color: white;

  .dashboard__burger-menu {
    position: absolute;
    top: 23px;
    right: -45px;
    z-index: 750;
    cursor: pointer;

    span {
      font-size: 20px;
      color: $primary;
    }

    &.burger-open {
      right: -9px;
      @media (max-width: 768px) {
        right: -380px;
        z-index: 800;
      }
      @media (max-width: 425px) {
        right: calc(-87vw - 8px);
      }
    }
  }

  .dashboard__aside-menu-position {
    width: 100%;
    height: 100%;
  }
}

.dashboard__aside-menu {
  width: 299px;
  height: 100%;
  transition: transform .2s cubic-bezier(.785, .135, .15, .86) 0s;
  position: relative;
  max-width: 370px;
  will-change: transform, width;
  border-right: 1px solid #dedede;
  animation: fadeInLeft;
  animation-duration: 200ms !important;

  &.close-animation {
    animation: fadeOutLeft;
    animation-duration: 200ms !important;
  }

  &.deactivate {
    display: none;
  }

  &.activate {
    display: block;
  }

  @media (max-width: 768px) {
    width: 87vw;
    position: absolute;
    z-index: 799;
    height: 100vh;
    background-color: #fff;
  }
}

.dashboard__aside-bar {
  width: 55px;
  height: 100%;
  padding-bottom: 30px;
  border-right: 1px solid #e9ebf0;
  background: #fff;
  display: grid;
  grid-template-rows: 1fr auto;
  will-change: transform, width;
  position: relative;
  overflow: hidden;
}

.dashboard__aside-brand {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 100%;
  height: 65px;
  text-align: center;

  img {
    width: 130px;
    margin-right: 15px;
  }

  span {
    font-size: 25px;
    font-weight: 700;
    color: $primary
  }

  @media (max-width: 768px) {
    padding: 0;
    img {
      width: 100px;
    }
  }
}

.dashboard__aside-content {
  padding: 0 40px;
}

.dashboard__aside-menu-head-user {
  width: 200px;
  padding: 10px 0;
}

.dashboard__body {
  height: 100%;
  max-height: 100vh;
  min-width: 0;
  min-height: 0;
  display: flex;
  overflow: hidden;
  position: relative;
  flex-direction: column;
}

.dashboard__navbar {
  grid-area: header;
  text-align: right;
  height: 70px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: white;
  box-shadow: 0 2px 8px #f0f1f2;
}

.dashboard__navbar-avatar {
  width: auto;
  height: 40px;
  border-radius: 40px;
  padding-right: 15px;
  background: rgba(60, 64, 67, 0.08);
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 768px) {
    margin-right: 10px;
  }
}

.dashboard__content {
  position: relative;
  height: 100%;
  padding: 20px 40px;
  overflow-y: auto;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 20px 20px;
  }
}

.dashboard__no-result {
  width: 100%;
  height: calc(100% - 30px);
  display: flex;
  justify-content: center;
  align-items: center
}

.dashboard__help-icon {
  margin-bottom: 20px;
  color: rgba(100, 100, 100, .8);

  &:hover {
    color: rgba(70, 70, 70, 1);
    cursor: pointer;
  }
}

.dashboard__loading-save {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: -10px;
  text-align: center;
  display: flex;
  justify-content: center;
  color: $dark;
  font-size: 12px;

  .ant-spin.ant-spin-spinning span {
    font-size: 18px;
  }
}

.aside__menu-item {
  width: 100%;
  text-align: left;

  &.active {
    background: rgba(0, 0, 0, 0.018);
    color: $primary;
    font-weight: 500;
  }
}

.aside__menu-menu {
  //max-width: calc(100% - 30px);
  .ant-dropdown-menu-item {
    padding: 10px 20px;
  }

  p {
    margin-block-start: 0;
    margin-block-end: 0;
  }
}

.aside__menu-item:hover {
  color: $primary !important;
  transition: all 350ms;
}
